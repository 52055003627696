import * as React from 'react';
import { Component } from "react";
import styled from 'styled-components';
import { CoverImage } from '../atoms/images/CoverImage';
import { AppColor } from '../../constants/Color';
import { DocumentCardTitle } from '../atoms/headings/DocumentCardTitle';
import { ArticleDate } from '../atoms/texts/ArticleDate';

const Card = styled.a<{}>`
  box-sizing: border-box;

  text-decoration: none;

  padding: 10px;

  width: 100%;
  height: 120px;

  background-color: white;

  transition-duration: 0.3s;

  &:hover{
    background-color: ${AppColor.PRIMARY_TRANS};

    transition-duration: 0.3s;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const TextContainer = styled.div<{}>`
  box-sizing: border-box;
  height: 100%;

  flex-grow: 1;

  margin-left: 10px;

  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

interface Property{
  cover? : any;
  title : string;
  date : Date;
  link : string;
}

export class DocumentCard extends Component<Property>{

  public render() : JSX.Element{
    return(
      <Card href={this.props.link}>
        <CoverImage image={this.props.cover} alt={this.props.title}/>
        <TextContainer>
          <DocumentCardTitle text={this.props.title} />
          <ArticleDate date={this.props.date} />
        </TextContainer>
      </Card>
    );
  }
}