import * as React from 'react';
import { PureComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
import styled from 'styled-components';

const StyledImg = styled(Img)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;

  z-index: 0;

  .gatsby-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    z-index: -1;
  }
`;

interface Property{
  image : any;
  alt: string;
}

export class CoverImage extends PureComponent<Property>{

  public render() : JSX.Element | null{
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "fabicon-256.png" }) {
          childImageSharp {
            sizes(maxWidth: 600){
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `);

    const image = this.props.image || data.file.childImageSharp.sizes;
    if(!image) return null;

    return(
      <StyledImg
        alt={this.props.alt}
        sizes={image}
        objectFit="cover"
      />
    );
  }
}