import * as React from 'react';
import { Component } from "react";
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import { SEO } from '../components/seo';
import { DocumentCard } from '../components/molecules/DocumentCard';
import { ArticleTitle } from '../components/atoms/headings/ArticleTitle';
import { HorizontalSeparator } from '../components/atoms/separators/HorizontalSeparator';
import { AppColor } from '../constants/Color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Item = styled.div<{}>`

  border-style: none;
  border-bottom-style: solid;
  border-color: ${AppColor.PRIMARY};
  border-width: 1px;

  &:first-of-type{
    border-top-style: solid;
    border-bottom-style: solid;
  }
`;

export default class articles extends Component<any>{
  
  private renderCard(node: any): JSX.Element{
    const cover = node.frontmatter.cover ? node.frontmatter.cover.childImageSharp.sizes : undefined;

    return(
      <Item key={node.id}>
        <DocumentCard
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          link={`/articles/${node.fields.slug}`}
          cover={cover}
        />
      </Item>
    );
  }

  public render(): JSX.Element{
    const edges = this.props.data.allMarkdownRemark.edges;

    return(
      <Layout>
        <SEO 
          isArticle={false}
          title={"Articles"}
          cover={undefined}
        />
        <ArticleTitle text="Articles"/>
        <HorizontalSeparator />
        <Container>
          {edges.map(({node}) => this.renderCard(node))}
        </Container>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ArticlesQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/articles//"}, frontmatter: {publish: {eq: "true"}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges{
        node{
          id
          frontmatter{
            title
            date(formatString: "MM/DD YYYY")
            cover{
              childImageSharp {
                sizes(maxWidth: 600){
                  ...GatsbyImageSharpSizes
                }
              }
            }
            publish
          }
          fields {
            slug
          }
        }
      }
    }
  }
`